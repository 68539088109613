<template>
  <div>
    <TAdvancedSearch ref="TAdvancedSearch">
      <template v-slot:advanced-search>
        <Advanced
          v-if="tableSubmit != null"
          :searchConfiguration.sync="searchConfiguration"
          :searchObj.sync="tableSubmit"
          :tableId.sync="tableData.tabId"
        ></Advanced>
      </template>
    </TAdvancedSearch>

    <KTPortlet>
      <template v-slot:body>
        <b-overlay :show="loading" class="head-loading">
          <div class="operate-box">
            <Export
              v-b-popover.hover.topleft=""
              :exportText="'Export Reservation'"
              :exportUrl="exportUrl"
              :exportDisabled="exportDisabled"
              fileName="Reservation list"
            ></Export>
            <ConfirmPurchaseoOverview
              :selectData="selected"
              :ids="selectIds"
              :disabled="loading"
              :initDataFn="renewTable"
            >
            </ConfirmPurchaseoOverview>
            <DeliveryPurchaseOverview
              :selectData="selected"
              :ids="selectIds"
              :disabled="loading"
              :initDataFn="renewTable"
            >
            </DeliveryPurchaseOverview>
            <Export
              v-b-popover.hover.topleft=""
              :exportText="'Export Confirmed Purchase'"
              :exportUrl="exportConfirmedPurchaseUrl"
              :exportDisabled="exportConfirmedPurchaseDisabled"
              fileName="Purchase Confirmed"
            ></Export>
          </div>
          <CustomTable
            v-if="tableSubmit != null"
            :itemsFn="itemsFn"
            :tableData="tableData"
            :sortName="sort_name"
            :tableObj="tableSubmit"
            :checkboxChange="checkboxChange"
            :checkboxAll="checkboxAll"
            :tableId.sync="tableData.tabId"
          ></CustomTable>
        </b-overlay>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import TAdvancedSearch from '@/components/TAdvancedSearch/Index.vue';
import KTPortlet from '@/views/content/Portlet';
import Advanced from '@/components/Search/Ordinary.vue';
import CustomTable from '@/components/Table/Index.vue';
import Export from '@/components/Export/Index.vue';
import ConfirmPurchaseoOverview from '@/views/content/ConfirmPurchaseOverview/Index.vue';
import DeliveryPurchaseOverview from '@/views/content/DeliveryPurchaseOverview/Index.vue';

import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
import apiBus from '@/common/apiBus/index';
import { mapGetters } from 'vuex';
import _ from 'lodash';
export default {
  name: 'PurchaseTarget',
  components: {
    TAdvancedSearch,
    KTPortlet,
    Advanced,
    CustomTable,
    Export,
    ConfirmPurchaseoOverview,
    DeliveryPurchaseOverview
  },
  data() {
    return {
      // 高级搜索配置
      loading: false,
      exportUrl: '',
      exportDisabled: true,
      exportConfirmedPurchaseUrl: '/rest/api/purchase/download-purchase-tracking-confirm',
      exportConfirmedPurchaseDisabled: false,
      exportErrorText: '',
      searchConfiguration: {
        status: {
          type: 'checked-all',
          text: 'Status',
          class: 'text_color_all',
          allSelected: false,
          md: 12,
          resetVal: [100, 110, 120, 125, 150],
          options: [
            { label: 'Reserved', value: 100, class: 'text_yellow' },
            { label: 'Confirmed', value: 110, class: 'text_blue' },
            {
              label: 'Partial Delivered',
              value: 125,
              class: 'text_blue_purple'
            },
            { label: 'Delivered', value: 120, class: 'text_green' },
            { label: 'Stored', value: 150, class: 'text_green2' },
            { label: 'Failed', value: 160, class: 'text_red' },
            { label: 'Voided', value: 130, class: 'text_black' }
          ]
        },
        settlement_type: {
          type: 'checked-all',
          class: 'text_color_all',
          text: 'Settlement Type',
          allSelected: false,
          md: 6,
          // resetVal: [1, 2],
          options: [
            { label: 'Partner Contract', value: 1 },
            { label: 'Direct Payment', value: 2 }
          ]
        },
        order_type: {
          type: 'checked-all',
          text: 'Order Type',
          allSelected: false,
          class: 'text_color_all',
          resetVal: [1, 2, 3],
          md: 6,
          options: [
            { label: 'Retail', value: 1 },
            { label: 'Wholesale', value: 2 },
            { label: 'Deposit', value: 3 },
            { label: 'Fulfillment Only', value: 4 }
          ]
        },
        invoice_status: {
          type: 'checked-all',
          class: 'text_color_all',
          text: 'Invoice Status',
          allSelected: true,
          md: 12,
          resetVal: [0, 1, 2],
          options: [
            { label: 'Pending', value: 0, class: 'text_orange_dashed' },
            { label: 'Processing', value: 1, class: 'text_blue_dashed' },
            { label: 'Completed', value: 2, class: 'text_green_dashed' }
          ]
        },
        supplier: {
          type: 'select',
          text: 'Supplier Name',
          placeholder: this.$t('page.please_choose'),
          options: [],
          hideLoading: true,
          input: this.changeSupplier,
          onSearch: this.onSearch
        },
        channel: {
          type: 'select',
          text: 'Channel',
          placeholder: this.$t('page.please_choose'),
          hideLoading: true,
          options: []
        },
        reserve_no: {
          type: 'input',
          text: 'Reserve No.',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        },
        purchase_id: {
          type: 'input',
          text: 'Purchase ID',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        },

        purchase_date: {
          type: 'datepicker_from_to',
          md: '6',
          text: 'Purchase Date',
          limit: {
            // start: null,
            end: 0
          },
          from: {
            placeholder: 'From'
            // resetVal: this.format(new Date().setDate(new Date().getDate() - 7))
          },
          to: {
            placeholder: 'To'
            // resetVal: this.format(new Date())
          }
        },
        cps_order_number: {
          type: 'input',
          text: 'Order ID',
          input_type: 'text',
          // icon: 'bar-chart-fill',
          placeholder: this.$t('page.please_choose_input')
        },
        keyword: {
          type: 'input',
          text: 'Keyword',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        }
      },
      tableData: {
        fields: [
          {
            key: 'select',
            label: 'Select',
            variant: 'select',
            allSelect: false
          },
          {
            key: 'purchase_information',
            label: 'Purchase Information',
            sortable: true,
            variant: 'filter table-mx-180'
          },
          {
            key: 'order_information',
            label: 'Order Information',
            // sortable: true,
            variant: 'filter table-mx-180'
            // sortDesc: true
          },
          // {
          //   key: 'related_information',
          //   label: 'Related Information',
          //   // sortable: true,
          //   variant: 'filter table-mx-180'
          //   // sortDesc: true
          // },
          {
            key: 'last_modify',
            label: 'Last Modify'
          },
          {
            key: 'supplier_name',
            label: 'Supplier Name',
            variant: 'w-300'
          },
          {
            key: 'channel_name',
            label: 'Channel Name',
            variant: 'w-300'
          },
          {
            key: 'image',
            label: 'Image',
            variant: 'w-110'
          },
          {
            key: 'article_information',
            label: 'Article Information',
            variant: 'mx-300'
          },

          {
            key: 'qty',
            label: 'Purchase Quantity'
          },
          {
            key: 'stored_qty',
            label: 'Storage Quantity'
          },
          {
            key: 'invoice_status',
            label: 'Invoice Status'
          },
          {
            key: 'status',
            label: 'Status'
          },
          {
            key: 'action',
            label: ''
          }
        ],
        tabId: 'purchase_overview',
        rowClass: '',
        class: 'new_table',
        items: [],
        status: null,
        totalItems: 9999999999999,
        pageOptions: [10, 30, 50, 100, 200]
      },
      // 搜索提交数据
      tableSubmit: null,
      initialTableSubmit: {
        status: [100, 110, 120, 125, 150],
        settlement_type: [],
        invoice_status: [0, 1, 2],
        order_type: [1, 2, 3],
        supplier: null,
        channel: null,
        purchase_id: null,
        purchase_date_from: null,
        sort: null,
        purchase_date_to: null,
        cps_order_number: null,
        reserve_no: null,
        keyword: null,
        limit: 100,
        page: 1
      },
      sort_name: '',

      // 表格数据
      save_data: [],
      selected: [],
      selectIds: []
    };
  },

  methods: {
    searchTableData(data) {
      var tableData = [];
      data.forEach((key) => {
        let {
          id,
          purchase_id,
          purchase_date,
          // priority,
          last_modify,
          supplier_name,
          channel_name,
          image_url,
          brand,
          brand_article_number,
          product_name,
          product_name_en,
          color,
          color_name,
          size,
          purchase_price,
          qty,
          status,
          product_id,
          pos_article_no,
          supplier_id,
          logistics,
          tracking_no,
          reserve_no,
          pick_up_by,
          delivery_qty,
          stored_qty,
          cps_order_number,
          order_date,
          cps_order_url,
          position,
          invoice_status,
          related_information
        } = key;

        tableData.push({
          id: id,
          select: false,
          purchase_information: {
            purchase_id: purchase_id,
            purchase_date: purchase_date,
            reserve_no: reserve_no
          },
          related_information,
          // order_priority: priority,
          last_modify: last_modify,
          supplier_name: supplier_name,
          channel_name: channel_name,
          purchase_price: purchase_price,
          image: image_url,
          article_information: {
            brand: brand,
            brand_article_number: brand_article_number,
            product_name:
              product_name_en != '' && product_name_en != null
                ? product_name_en
                : product_name,
            color: color,
            color_name: color_name,
            size: size,
            pos_article_no: pos_article_no
          },
          order_information: {
            cps_order_number: cps_order_number,
            order_date: order_date,
            cps_order_url: cps_order_url,
            position: position
          },
          stored_qty,
          qty: qty,
          status: status,
          product_id: product_id,
          supplier_id: supplier_id,
          tracking_no: tracking_no,
          pick_up_by: pick_up_by,
          logistics: logistics,
          delivery_qty: delivery_qty,
          invoice_status:
            this.searchConfiguration.invoice_status.options[invoice_status]
              .label,
          action: 'PurchaseOverview'
        });
      });
      return tableData;
    },
    itemsFn(ctx) {
      switch (ctx.sortBy) {
        case 'purchase_information':
          if (ctx.sortDesc) {
            this.tableSubmit.sort = {
              purchase_information: 'desc'
            };
          } else {
            this.tableSubmit.sort = {
              purchase_information: 'asc'
            };
          }
          break;
        // case 'order_information':
        //   if (ctx.sortDesc) {
        //     this.tableSubmit.sort = {
        //       order_information: 'desc'
        //     };
        //   } else {
        //     this.tableSubmit.sort = {
        //       order_information: 'asc'
        //     };
        //   }
        //   break;
        case '':
          this.tableSubmit.sort = null;
          break;
      }
      this.CACHE.addCache(
        this.currentUser.uid,
        'PurchaseTrackingt',
        this.tableSubmit
      );
      this.loading = true;
      this.save_data = [];
      return apiBus.purchase
        .purchaseOverviewList(this.tableSubmit)
        .then((data) => {
          // console.log(data);
          this.loading = false;
          this.tableData.items = this.searchTableData(data.data.data.list);
          this.tableData.totalItems = data.data.data.total;
          this.tableData.status = data.data.message;
          return this.tableData.items;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
          this.tableData.totalItems = 0;
          return [];
        });
    },
    exportUrlFn() {
      // console.log();
      if (this.save_data.length > 0) {
        var ids = [];
        this.save_data.forEach((key) => {
          ids.push(this.tableData.items[key].id);
        });
        var reservationUrl =
          '/rest/api/purchase/download-purchase-overview-list';
        var dataUrl = 'id=' + ids.toString();
        this.exportUrl = reservationUrl + '?' + dataUrl;
        var supplier_ids = [];
        this.save_data.forEach((key) => {
          supplier_ids.push(this.tableData.items[key].supplier_id);
        });
        Array.from(new Set(supplier_ids)).length == 1
          ? ((this.exportDisabled = false), (this.exportErrorText = ''))
          : ((this.exportDisabled = true),
            (this.exportErrorText = 'purchase supplier有多个或没有供应商'));
      } else {
        this.exportDisabled = true;
        this.exportErrorText = '';
        this.exportUrl = '';
      }
    },
    renewTable() {
      this.$root.$emit('bv::refresh::table', this.tableData.tabId); // 筛选强制刷新表单
    },
    ifAllCheckbox() {
      var checkboxLength = [],
        ifCheckboxL = [];
      this.tableData.items.forEach((itme) => {
        if (itme.select) {
          checkboxLength.push(itme.select);
        }
        if (itme.selectDisabled != 'disabled') {
          ifCheckboxL.push(itme.select);
        }
      });

      if (
        checkboxLength.length == ifCheckboxL.length &&
        ifCheckboxL.length > 0
      ) {
        this.tableData.fields[0].allSelect = true;
      } else {
        this.tableData.fields[0].allSelect = false;
      }

      this.exportUrlFn();
    },
    checkboxChange(select, index, data) {
      var splice_index = this.save_data.indexOf(index);
      if (select) {
        this.save_data.push(index);
        this.selected.push(data);
        this.selectIds.push(data.id);
      } else {
        this.save_data.splice(splice_index, 1);
        this.selected.splice(splice_index, 1);
        this.selectIds.splice(splice_index, 1);
      }
      this.ifAllCheckbox();
    },
    checkboxAll(select) {
      if (select) {
        this.tableData.items.forEach((item) => {
          item.select = true;
        });
      } else {
        this.tableData.items.forEach((item) => {
          item.select = false;
        });
      }
    },
    add0(m) {
      return m < 10 ? '0' + m : m;
    },
    format(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      return y + '-' + this.add0(m) + '-' + this.add0(d);
    },

    onSearch(search, loading) {
      if (search != null && search != '') {
        loading(true);
        this.search(loading, search, this);
      }
    },

    search: _.debounce((loading, search, vm) => {
      apiBus.purchase
        .getSupplierList({
          keyword: search
        })
        .then((data) => {
          vm.searchConfiguration.supplier.options =
            data.data.data.supplier_list['Other Supplier'];
          loading(false);
        })
        .catch((error) => {
          console.log(error);
          vm.searchConfiguration.supplier.options = [];
          loading(false);
        });
    }, 350),

    changeSupplier(e) {
      this.searchConfiguration.channel.options = [];
      this.tableSubmit.channel = null;
      if (
        this.tableSubmit.supplier != '' &&
        this.tableSubmit.supplier != null
      ) {
        this.searchConfiguration.channel.hideLoading = false;
        apiBus.purchase
          .getChannelList({ supplier: e })
          .then((data) => {
            this.searchConfiguration.channel.options =
              data.data.data.channel_list;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.searchConfiguration.channel.hideLoading = true;
      }
    },
    init() {
      if (this.CACHE.ifCache('PurchaseTrackingt')) {
        this.tableSubmit = this.CACHE.getCache(
          this.currentUser.uid,
          'PurchaseTrackingt'
        );
        Object.keys(this.searchConfiguration).forEach((key) => {
          if (this.searchConfiguration[key].type == 'checked-all') {
            if (
              this.tableSubmit[key].length ==
              this.searchConfiguration[key].options.length
            ) {
              this.searchConfiguration[key].allSelected = true;
            } else {
              this.searchConfiguration[key].allSelected = false;
            }
          }
        });
        this.$refs.TAdvancedSearch.visible =
          this.CACHE.getVisible('PurchaseTrackingt');
      } else {
        this.tableSubmit = this.initialTableSubmit;
      }

      if (
        this.tableSubmit.supplier != '' &&
        this.tableSubmit.supplier != null
      ) {
        this.searchConfiguration.channel.hideLoading = false;
        apiBus.purchase
          .getChannelList({ supplier: this.tableSubmit.supplier })
          .then((data) => {
            this.searchConfiguration.channel.options =
              data.data.data.channel_list;
          })
          .catch((error) => {
            console.log(error);
          });
      }

      apiBus.purchase
        .getSupplierList()
        .then((data) => {
          let options = [];
          Object.keys(data.data.data.supplier_list).forEach((item) => {
            options.push({
              label: item,
              value: item.replace(/ /g, '_'),
              index: 1,
              disabled: true
            });
            data.data.data.supplier_list[item].forEach((x) => {
              options.push({
                label: x.label,
                value: x.value,
                index: 2
              });
            });
          });
          this.searchConfiguration.supplier.options =
            data.data.data.supplier_list['Other Supplier'];
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  watch: {
    async 'tableSubmit.supplier'(n, o) {
      if (n == null && o != null) {
        const { data } = await apiBus.purchase.getSupplierList();
        this.searchConfiguration.supplier.options =
          data.data.supplier_list['Other Supplier'];
      }
    }
  },
  computed: {
    ...mapGetters(['currentUser']) // 获取用户名
  },
  mounted() {
    this.init();
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId == 'advanced-search') {
        this.CACHE.addVisible('PurchaseTrackingt', isJustShown);
      }
    });
  }
};
</script>
<style scoped></style>
